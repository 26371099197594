.inputError {
  border: 1px solid var(--colorFail);
}

.label {
  width: fit-content;
}

.labelWrapper {
  display: flex;
}
