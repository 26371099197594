.root {
  padding: 12px 24px;
  border: 1px solid var(--colorGrey100);
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.actionBtns {
  display: flex;

  align-items: center;

  & > span {
    margin: 0 12px;
  }
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
