.title {
  composes: marketplaceModalTitleStyles from global;

  margin-right: 46px;
  margin-bottom: 40px;
}

.messages {
  composes: marketplaceModalParagraphStyles from global;

  &:last-of-type {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--colorFail);
}

.buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 48px;
  gap: 12px;
}

.button {
  width: auto;
  min-height: unset;
}

.primaryBtn {
  composes: button;

  padding: 6px 12px;
  min-width: 100px;
}
