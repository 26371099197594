@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  color: var(--colorGrey400);

  @media (--viewportSmall) {
    justify-content: center;
  }
}

.link {
  display: flex;
  align-items: center;

  padding: 6px;
  cursor: pointer;

  svg {
    color: var(--colorGrey400);
  }
}

.disabledLink {
  display: flex;
  align-items: center;
  padding: 6px;

  svg {
    color: var(--colorGrey200);
  }
}

.date {
  display: flex;
  align-items: center;
  gap: 6px;
}
