.root {
  display: flex;
  gap: 10px;
}

.hourInput {
  color: var(--colorGrey700);
}

.placeholder {
  color: var(--colorGrey300);
}
