.root {
}

.inputError {
  border: var(--borderErrorField);
}

.labelWrapper {
  display: flex;
}
