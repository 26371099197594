.root {
}

.trigger {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--colorGrey600);
  color: var(--colorWhite);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  font-size: 10px;
}
