.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  opacity: 20%;
  color: var(--marketplaceColor);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;

  gap: 16px;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */

.toPageLink {
  /* Dimensions */
  padding: 0 5px;
  margin-top: auto;
  margin-bottom: auto;

  font-weight: 400;
  font-size: 14px;

  /* Color for svg icons */
  color: var(--colorGrey400);

  &:hover {
    text-decoration: none;
  }
}

.currentPage {
  /* Dimensions */
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  font-weight: 600;
  background-color: var(--marketplaceColor);

  /* Color */
  color: var(--colorWhite);
}

.paginationGap {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
