@import '../../styles/customMediaQueries.css';

.root {
}

.blogs {
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    padding: 0;
    margin: 0 0 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.blog {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: var(--boxShadowListingCard);
  border-radius: var(--borderRadiusCard);
  transition: var(--transitionStyleButton);

  text-decoration: none;

  &:hover {
    text-decoration: none;
    transform: scale(1.01);
  }

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: unset;
  }
}

.aspectRatioWrapper {
  background: var(--colorGrey100); /* Loading BG color */

  border-radius: var(--borderRadiusCard) var(--borderRadiusCard) 0 0;

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusCard) 0 0 var(--borderRadiusCard);
    max-width: 260px;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: var(--borderRadiusCard) var(--borderRadiusCard) 0 0;

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusCard) 0 0 var(--borderRadiusCard);
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 12px;
  text-align: center;
  background-color: var(--colorWhite);
  border-radius: inherit;

  @media (--viewportMedium) {
    text-align: left;
    align-items: unset;
    flex: 1;
  }
}

.blogTitle {
  color: var(--colorGrey800);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.blogFor {
  display: flex;
  align-items: center;
  color: var(--marketplaceColor);

  font-size: 12px;
  line-height: 18px;

  & > svg {
    width: 16px;
    min-width: 16px;
    margin-right: 4px;
  }

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 22px;
  }
}

.truncateOverlow {
  position: relative;
  max-height: calc(18px * 3);
  overflow: hidden;

  &::before {
    position: absolute;
    content: '...';
    inset-block-end: 0;
    inset-inline-end: 0;
    width: 3rem;
    padding-left: 2px;
    background: var(--colorWhite);
  }

  @media (--viewportMedium) {
    max-height: calc(22px * 3);
  }
}

.blogDesc {
  composes: truncateOverlow;

  font-size: 12px;
  line-height: 18px;
  color: var(--colorGrey500);

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 22px;
  }
}

.error {
  color: var(--colorFail);
}
