@import '../../styles/customMediaQueries.css';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputContainer {
  border-style: dashed;
  border-color: var(--colorGrey100);
  border-width: 2px;
  border-radius: 2px;
  background-color: var(--colorGrey50);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 36px 10px;
  padding-bottom: 12px;

  &:hover {
    border-color: var(--colorGrey400);
  }
}

.videoLinkInput {
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;

  input {
    font-size: 14px;
  }
}

.pasteBtn {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.pasteIcon {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  color: var(--colorGrey500);
}

.pasteIcon:hover {
  cursor: pointer;
}

.pasteIcon:hover + .helperText {
  display: inline;
}

.uploadBtn {
  width: 130px;
  border-radius: 8px;
  background-color: var(--colorGrey400);
  margin-top: 20px;
  padding: 0 8px;

  &:hover&:enabled,
  &:focus {
    background-color: var(--colorGrey500);
  }
}

.videoWrapper {
  position: relative;
  width: 100%;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;
  z-index: 1000;
  border-top-right-radius: 6px;

  svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--colorGrey300);
    stroke: var(--colorGrey300);
  }
}

.videosWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.errorWrapper {
  height: 20px;
  margin-top: 4px;
}

.invalidUrl {
  color: var(--colorFail);
}

.inputError {
  background-color: var(--colorFailLight);
  border-color: var(--colorFail);

  &:hover {
    border-color: var(--colorFail);
  }
}
