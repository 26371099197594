.root {
}

.addOns {
  min-width: 0;
  max-height: 300px;
  overflow-y: auto;
  padding: 20px 16px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: none;

  border: 2px solid var(--colorLightGrey);

  color: var(--colorDarkBlue);

  /* Show shadows to indicate that element can be scrolled */
  background:
   /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background:
   /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;

  & > fieldset {
    min-width: 0;

    &:not(&:last-of-type) {
      margin-bottom: 12px;
    }
  }
}

.addOnCategory {
  margin-bottom: 8px;
  text-align: center;

  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  color: var(--colorGrey);

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-top: 1px solid #ececec;
  }
}

.addOnCheckbox {
  & > input:disabled {
    & + label {
      opacity: 0.5;
    }
  }

  & > label {
    justify-content: space-between;

    & > span {
      &:first-of-type {
        margin: 0 0 0 10px;
        order: 2;
        align-self: unset;
      }

      &:last-of-type {
        order: 1;
        flex: 1;
        min-width: 0;
        margin: 0;
      }
    }
  }
}

.addOn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconWrapper {
  min-width: 40px;
  min-height: 40px;
  width: 36px;
  height: 36px;
  padding: 6px;
  background-color: var(--colorAlmostWhite);
  border: 1px solid #eeeeee;
  border-radius: 4px;

  & > svg {
    width: auto;
    height: 100%;
    color: var(--colorGrey700);
  }
}

.addOnTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--colorDarkBlue);
}

.price {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-left: auto;
  color: var(--marketplaceColor);

  & > span {
    display: inline-block;
    font-size: 10px;
    line-height: 10px;
    color: var(--marketplaceColorLight);
  }
}
