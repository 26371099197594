@import '../../styles/customMediaQueries.css';

.tooltipTrigger {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.tooltip {
  padding: 0 !important;
  opacity: 1 !important;
  background-color: var(--colorWhite) !important;
  z-index: 11;
  border-radius: var(--borderRadiusLarge) !important;
}

.bookingsPreview {
  @media (--viewportMedium) {
    /* min-width: 278px; */
  }
}

.bookingLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: var(--borderRadiusLarge);
  color: var(--colorBlack);
  background: var(--colorWhite);
  z-index: 11;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--marketplaceColorLight);
    color: var(--colorWhite);
  }
}

.customerName {
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (--viewportSmall) {
    /* max-width: 158px; */
  }
}

.seats {
  margin-left: 12px;
}
