@import '../../styles/customMediaQueries.css';

.locationIcon {
  color: var(--marketplaceColor);

  position: absolute;
  left: 11px;
  bottom: 7px;

  @media (--viewportMedium) {
    bottom: 14px;
  }
}

.locationInput {
  padding-left: 40px;
}
