:root {
  --PrivacyPolicyContent_itemTypeMinSize: 34px;
}

.root {
  & h2 {
    font-size: 16px;
    line-height: 18px;
    margin: 12px 0;
    display: inline;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & table {
    margin: 12px 0;
    width: 100%;

    & td,
    & th {
      border: 1px solid var(--colorGrey700);
      padding: 6px;
      overflow-wrap: anywhere;
    }
  }

  & ul {
    & ul {
      margin: 12px 0;
    }
  }

  & li {
    padding: 6px 0;

    & > h2 {
      padding-left: 16px;
    }
  }

  & ol {
    padding-left: 0;

    & > li {
      & > div {
        display: inline;
      }
    }

    & ol {
      margin: 12px 0;
    }
  }

  & hr {
    border: 0;
    border-bottom: 1px solid var(--marketplaceColor);
    margin: 12px 0 0;
  }
}

.center {
  text-align: center;
}

.indent {
  padding-left: 24px;
}

.discList {
  /* list-style-type: disc; */

  & > li {
    display: flex;

    &::before {
      content: '•';
      min-width: var(--PrivacyPolicyContent_itemTypeMinSize);
      display: inline-block;
      font-size: 24px;
    }
  }
}

.orderList {
  counter-reset: item;
  list-style-position: inside;

  & > li {
    display: block;

    &::before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      min-width: var(--PrivacyPolicyContent_itemTypeMinSize);
      display: inline-block;
    }

    & > div {
      padding-left: 16px;
    }
  }
}
