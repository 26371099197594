@import '../../styles/customMediaQueries.css';

.root {
}

.labelWrapper {
  display: flex;
}

.menu {
  display: inline-block;
}

.menuLabel {
  composes: marketplaceInputStyles from global;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 64px;
  width: 64px;
  padding: 6px;

  & svg {
    width: auto;
    height: 100%;
  }
}

.labelSelected {
  color: var(--marketplaceColor);
}

.placeholderIcon {
  padding-top: 10px;
}

.menuContent {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 64px);
  margin: 6px;
  gap: 6px;
  overflow-y: auto;
  max-height: 300px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, minmax(64px, 1fr));
  }
}

.iconBtn {
  padding: 6px;
  width: 64px;
  height: 64px;

  & svg {
    width: auto;
    height: 100%;
  }

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorLight);
  }
}

.img {
  height: 100%;
  width: 100%;
}

.iconSelected {
  border-color: var(--marketplaceColor);
}

.error {
  border: var(--borderErrorField);
}
