@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.reviewItem {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.review {
  display: flex;
  flex-direction: column;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;

  border: 2px solid var(--marketplaceColor);

  width: 44px;
  height: 44px;
}

.reviewContent {
  margin: 0;
  white-space: pre-line;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: var(--colorGrey);

  @media (--viewportXSmall) {
    margin-top: 20px;
  }
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--colorGrey300);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;

  line-height: 12px;
}

.author {
  margin: 0;
  padding: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--colorDarkBlue);
}

.date {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: var(--colorDarkBlue);
  margin: 6px 0;

  @media (--viewportXSmall) {
    margin: 0;
  }
}

.reviewHeader {
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  @media (--viewportXSmall) {
    flex-direction: row;
    align-items: center;
  }
}

.withoutDate {
  display: flex;
  align-items: center;
}

.ratingAndNameWrapper {
  display: flex;
  flex-direction: column;
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.divider {
  margin: 16px 0;
  height: 1px;
  background-color: #efefef;
  border: none;
}

.noReviews {
  font-style: italic;
  padding: 20px 0;
}

.showMoreBtn {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  align-self: center;
}

.infoContainer {
  display: flex;
  column-gap: 24px;
  row-gap: 6px;
  flex-wrap: wrap;

  margin-top: 12px;
  margin-bottom: 24px;

  @media (--viewportXSmall) {
    margin-bottom: 0;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 0 1 200px;

  color: var(--colorDarkBlue);
  font-size: 14px;

  &:first-child {
    flex-basis: 150px;
  }

  svg {
    color: var(--marketplaceColor);
    flex-shrink: 0;
  }
}
