:root {
  --BookingContract_itemTypeMinSize: 34px;

  --BookingContract_nestedItemTypeMinSize: 40px;
}

.root {
  & p {
    margin: 0;
  }

  & h2 {
    font-size: 16px;
    line-height: 18px;
    margin: 12px 0;
    display: inline;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & table {
    margin: 12px 0;

    & td,
    & th {
      border: 1px solid var(--colorGrey700);
      padding: 6px;
    }
  }

  & ul {
    & ul {
      margin: 12px 0;
    }
  }

  & li {
    padding: 6px 0;

    & > h2 {
      padding-left: 16px;
    }
  }

  & ol {
    padding-left: 0;

    & > li {
      & > div {
        display: inline;
      }
    }

    & ol {
      margin: 12px 0;
    }
  }

  & hr {
    border: 0;
    border-bottom: 1px solid var(--marketplaceColor);
    margin: 12px 0 0;
  }
}

.discList {
  /* list-style-type: disc; */

  & > li {
    display: flex;

    &::before {
      content: '•';
      min-width: var(--BookingContract_itemTypeMinSize);
      display: inline-block;
      font-size: 24px;
    }

    & > div {
      padding-left: 16px;
    }
  }
}

.itemNestedList {
  flex-wrap: wrap;

  & > div {
    flex: 1 1;
  }

  & > ul,
  & > ol,
  & > table {
    flex: 1 1 100%;
    padding-left: calc(var(--BookingContract_itemTypeMinSize) + 16px);
    list-style-position: inside;
  }

  & > table {
    margin-left: calc(var(--BookingContract_itemTypeMinSize) + 16px);
  }
}

.dashList {
  & > li {
    display: flex;

    &::before {
      content: '-';
      min-width: var(--BookingContract_itemTypeMinSize);
      display: inline-block;
      font-size: 24px;
    }

    & > div {
      padding-left: 12px;
    }
  }
}

.orderList {
  counter-reset: item;
  list-style-position: inside;

  & > li {
    display: block;

    &::before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      min-width: var(--BookingContract_itemTypeMinSize);
      display: inline-block;
    }

    & > div {
      padding-left: 16px;
    }
  }
}

.nestedOrderList {
  composes: orderList;

  & > li {
    display: flex;
  }
}

.secondNestedOrderList {
  & > li {
    &::before {
      min-width: var(--BookingContract_nestedItemTypeMinSize);
    }
  }
}
