@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: var(--boxShadowListingCard);
  border-radius: var(--borderRadiusCard);
  color: var(--colorGrey500);

  font-size: 12px;
  line-height: 18px;
  min-width: 0;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 22px;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */

  border-top-right-radius: var(--borderRadiusCard);
  border-top-left-radius: var(--borderRadiusCard);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-top-right-radius: var(--borderRadiusCard);
  border-top-left-radius: var(--borderRadiusCard);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 14px 14px;
}

.title {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 4px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
}

.location {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  min-width: 0;

  & > svg {
    width: 14px;
    min-width: 14px;
    margin: 0 2px;
  }

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rating {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  & > b {
    color: var(--colorGrey800);
  }

  & > svg {
    width: 18px;
  }
}

.categories {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  min-width: 0;

  & > svg {
    width: 18px;
    min-width: 18px;
  }

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 4px;
}

.priceValue {
  font-weight: var(--fontWeightRegular);

  /* Remove default margins from font */
  margin: 0;

  color: var(--marketplaceSecondary);
}

.perUnit {
  font-weight: var(--fontWeightRegular);

  display: inline-flex;
  align-items: center;

  /* Remove default margins from font */
  margin: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
