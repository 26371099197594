.root {
}

.labelWrapper {
  display: flex;
}

.selectError {
  border: var(--borderErrorField);
}
