@import '../../../styles/customMediaQueries.css';

.root {
  margin: 24px 24px 0 24px;

  h2 {
    padding: 0;
    margin: 0;

    text-align: center;
    text-transform: none;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: var(--colorTeal);
  }

  p {
    color: var(--colorGrey600);
    font-weight: 400;
  }
  @media (--viewportMedium) {
    margin-top: 0;
  }
  @media (--viewportLarge) {
    margin: unset;
  }
}

.contactUsLink {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  display: block;
}

.images {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.imgContainer {
  position: relative;

  height: 64px;
  width: 64px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
