.cellWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--colorGrey500);

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      border: 1px solid var(--marketplaceColor);
    }
  }
}

.hasBooking {
  color: var(--colorWhite);
  text-decoration: none;
  background-color: var(--marketplaceColor);
}

.hasSession {
  color: var(--colorWhite);

  :global(.rbc-now) & {
    color: var(--colorWhite);
  }

  text-decoration: none;
}

.disabled {
  color: var(--colorGrey500);
  text-decoration: none;
}

.unavailable {
  color: var(--colorWhite);

  :global(.rbc-now) & {
    color: var(--colorSuccess);
  }
}
