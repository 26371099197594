.root {
  width: 143px;

  box-shadow: 0px 2px 20px -5px rgba(99, 48, 187, 0.13);
  border-radius: 12px;
  background-color: var(--colorWhite);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  height: 90px;
  width: 90px;
  margin-top: 8px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.actionBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;

  & > span {
    margin: 0 12px;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  max-width: 100px;

  color: var(--colorDarkBlue);
  margin-top: 10px;
}

.category {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: rgba(160, 156, 167, 1);
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;

  color: var(--marketplaceColor);

  margin-bottom: 15px;
}
