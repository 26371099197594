@import '../../styles/customMediaQueries.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  z-index: 1000;

  border-radius: var(--borderRadiusCard);
  border: 1px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowPopup);

  /* Dimensions */
  padding: 24px;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Colors */
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    left: 36px;
    right: 36px;
    bottom: 36px;
  }
}

.rootExpanded {
  top: 20%;

  @media (--viewportMedium) {
    top: unset;
  }
}

.message {
  composes: h5 from global;
  padding: 0;
  margin: 0;
  text-align: center;
}

.messageExpanded {
  overflow-y: auto;
  /* Show shadows to indicate that element can be scrolled */
  background:
   /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background:
   /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;

  @media (--viewportMedium) {
    overflow-y: unset;
  }
}

.ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;

  & > button {
    min-height: unset;
    white-space: nowrap;
    flex: 1 1 100%;
  }

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.secondary {
  padding: 6px 16px;
}

.primary {
  padding: 8px 16px;
}

.cookieForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.toggle {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;

  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--colorGrey300);

  & span[class*='labelWrapper'] {
    flex-direction: column;
    align-items: flex-start;

    & > label {
      padding: 0 0 6px 0;
    }
  }

  & span[class*='slider'] {
    height: 24px;

    & > span {
      padding: 4px 12px;
    }
  }
}

.toggleLabelDesc {
  font-weight: var(--fontWeightRegular);
}
