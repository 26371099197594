@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 30px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 24px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --colorGrey100;
}

.fieldDateInput {
  flex-grow: 1;
}

.fieldSelect {
  flex-grow: 1;
  position: relative;
  margin: 0 24px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 28px;
    transform: translate(0, 50%);
    left: 11px;
    background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM9.99949 17.9999C5.58121 17.9999 1.99949 14.4182 1.99949 9.99988C1.99949 5.5816 5.58121 1.99988 9.99949 1.99988C14.4178 1.99988 17.9995 5.5816 17.9995 9.99988C17.9995 12.1216 17.1566 14.1564 15.6563 15.6567C14.1561 17.157 12.1212 17.9999 9.99949 17.9999ZM13.1002 10.63L11.0002 9.42V5C11.0002 4.44772 10.5524 4 10.0002 4C9.44787 4 9.00016 4.44772 9.00016 5V10C9.00016 10 9.00016 10.08 9.00016 10.12C9.00608 10.1889 9.02296 10.2564 9.05016 10.32C9.07075 10.3793 9.09757 10.4363 9.13016 10.49C9.15752 10.5468 9.19106 10.6005 9.23016 10.65L9.39016 10.78L9.48016 10.87L12.0802 12.37C12.2326 12.4564 12.405 12.5012 12.5802 12.5C13.0355 12.5032 13.4356 12.1983 13.5532 11.7583C13.6708 11.3184 13.4764 10.8545 13.0802 10.63H13.1002Z" fill="%23EE2677"/></svg>');
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  @media (--viewportMedium) {
    margin: 0;

    &::after {
      left: 11px;
      top: 38px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 11px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 11px;
      bottom: 19px;
    }
  }
}

.select {
  position: relative;
  padding-left: 40px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;

  height: 50px;

  font-size: 16px;
  font-weight: 400;
  box-shadow: none;
  border-radius: 12px;
  border: 2px solid #f4f4f4;

  color: var(--colorDarkBlue);

  @media (--viewportMedium) {
    padding-left: 40px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.arrowIcon {
  stroke: var(--colorWhite);
  fill: var(--colorWhite);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: calc(50% - 34px);

  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.startDate {
  width: 100%;
}
.endDateHidden {
  display: none;
}

.placeholder {
  color: var(--colorGrey300);
}

/**
CSS for hiding the end date above
**/
