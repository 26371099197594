@import '../../styles/customMediaQueries.css';

.toolbar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;

  @media (--viewportSmall) {
    font-size: 14px;
  }
}

.headers {
  /* Week/Day view has the time column at the left
    so we need to move the headers by that amount */
  padding-left: 40px;

  display: flex;
  align-items: center;
  position: relative;
  gap: 6px;
  width: 100%;
  margin-bottom: 12px;
  font-weight: var(--fontWeightBold);
  line-height: 16px;
  padding-right: 16px;

  @media (--viewportMedium) {
    padding-left: 46px;
  }
}

.headersMonth {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  padding-left: 0;
  padding-right: 0;
}

.navButton {
  border: 0;
  outline: none;
  padding: 6px;
  color: var(--marketplaceColor);
  cursor: pointer;
}

.absoluteNavButton {
  position: absolute;
}

.header {
  line-height: 18px;
}

.desktopHeader {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileHeader {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  & > button {
    margin-right: auto;
    text-decoration: underline;
  }
}

.monthChangeBtns {
  display: flex;
  align-items: center;
  margin-right: auto;

  & > span {
    min-width: 72px;
    text-align: center;
    font-weight: var(--fontWeightBold);
    margin: 0 6px;
  }
}

.yearSelect {
  width: auto;
}
