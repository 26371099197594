.tableWrapper {
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.row {
  border: 1px solid var(--colorGrey100);
}

.headerCell,
.bodyCell {
  padding: 16px 10px;
}

.headerCell {
  text-wrap: nowrap;
}

.bodyCell {
  text-align: center;
}

.noDataCell {
  text-align: center;
  font-style: italic;
  padding: 10px;
}
