@import '../../styles/customMediaQueries.css';

.root {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  & input:checked + .slider {
    &::before {
      transform: translate3d(100%, 0, 0);
    }

    & > span:last-of-type {
      color: var(--colorWhite);
    }
  }

  & input:not(:checked) + .slider {
    & > span:first-of-type {
      color: var(--colorWhite);
    }
  }
}

.labelWrapper {
  display: inline-flex;
}

.switch {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
  }
}

.slider {
  position: relative;
  display: flex;
  cursor: pointer;
  background-color: var(--colorWhite);
  transition: 0.3s;
  margin: 0;

  border-radius: 26px;

  border: 1px solid var(--marketplaceColor);

  &::before {
    position: absolute;
    content: '';
    width: 50%;
    top: -1px;
    bottom: -1px;
    background-color: var(--colorWhite);
    transition: 0.3s;
    background-color: var(--marketplaceColor);
    transform-origin: center;
    border-radius: inherit;
  }

  & > span {
    position: relative;
    z-index: 1;
    padding: 4px 16px;
    flex: 1 1 50%;
    user-select: none;
    min-width: 0;
    text-align: center;
  }
}

.sliderDynamicColor {
  border: 1px solid var(--colorGrey300);

  &::before {
    background-color: var(--colorGrey300);
  }
}
