@import '../../styles/customMediaQueries.css';

.root {
  padding: 24px;
  border: 1px solid var(--colorGrey100);
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.client {
  font-weight: 600;
  font-size: 18px;
}

.actionBtns {
  display: flex;

  align-items: center;

  & > span {
    margin: 0 12px;
  }
}

.header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;

  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.rating {
  padding: 0;
  margin: 0;
  line-height: 17px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  flex: 1;
  text-wrap: nowrap;

  svg {
    color: var(--marketplaceColor);
    flex-shrink: 0;
  }
}

.location {
  text-wrap: wrap;
}
